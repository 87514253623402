import React from "react";
import { Form, Input, Select, message, Switch, Button } from "antd";

import { ObjectId } from "bson";
import { setPensando, setStoreWidgets, store } from "../../../../store";
import { Api } from "../../../../api/configApi";
import Boton from "../../../../components/design/elementos/boton";
import {
  InputT,
  OptionT,
  SelectT,
} from "../../../../plugins/Formularios/FormularioTecclas";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import CreadorFormularios from "../../../../plugins/Formularios/CreadorFormularios";

function CrearActualizarWidgetMarlinBotonComponente(props) {
  const [loading, setLoading] = React.useState(false);
  const [insertOrUpdate, setInsertOrUpdate] = React.useState("InsertWidgets");
  // const [camposDefecto, setCamposDefecto] = React.useState({})
  // const refCamposDefecto = React.useRef({})
  const methods = useForm();
  const {
    control,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
    watch,
  } = methods;

  const onSubmit = (values) => {
    console.log(values)
    values.tipo = "marlinBoton";
    setPensando(true);
    setLoading(true);
    values.limiteCantidad = +values.limiteCantidad;
    values.limiteWidth = +values.limiteWidth;
    Api("Cuentas", insertOrUpdate, { _id: store.cuentaactiva }, values)
      .then((res) => {
        props.setVisible(false);
        setPensando(false);
        setLoading(false);
        return Api(
          "Cuentas",
          "Get",
          { _id: store.cuentaactiva },
          { widgets: 1 }
        );
      })
      .then((res) => {
        setStoreWidgets(res[0].widgets);
      })
      .catch((err) => {
        console.log(err);
        setPensando(false);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    reset();
    const objId = new ObjectId();
    setValue("_id", objId.toString());
  }, [props.visible]);

  React.useEffect(() => {
    if (props.data) {
      reset(props.data);
      setInsertOrUpdate("UpdateWidgets");
    } else {
      const objId2 = new ObjectId();
      setValue("_id", objId2.toString());
    }
  }, [props.data]);

  const listaDatos = [
    {
        tipoInput: "MigracionCamposListados",
        nombreInput: "fdgfdgdfgfd",
        labelInput: "Nombre a mostrar del input",
        obligatorio:{
          campo: true,
          condicion: true,
          valorReferencia: true
        }
      },
    {
        tipoInput: "CondicionesCamposListado",
        nombreInput: "fdgfdgggdfgfd",
        labelInput: "Nombre a mostrar del input",
        obligatorio:{
          campo: true,
          condicion: true,
          valorReferencia: true
        }
      },
    {
      tipoInput: "SelectT",
      opciones: [
        { name: "UNO", value: "uno" },
        { name: "DOS", value: "dos" },
      ],
      nombreInput: "casagre1",
      labelInput: "Nombre a mostrar del input",
      obligatorio: {
        valor: true,
      },
    },
    {
      tipoInput: "SelectTListados",
      nombreInput: "fdgfdgdfgfd",
      labelInput: "Nombre a mostrar del input",
      obligatorio:{
        valor: true
      }
    },
    {
      tipoInput: "SelectTVariables",
      nombreInput: "fedfe",
      labelInput: "Variable a mostrar del input",
      obligatorio:{
        valor: true
      }
    },
    {
      tipoInput: "SelectTCamposListado",
      nombreInput: "amiguitos",
      labelInput: null,
      obligatorio:{
        valorListado: true,
        valorListadoCampo: true
      }
    },
    {
      tipoInput: "SelectTAtributosTexto",
      nombreInput: "atributoTexto",
      labelInput: null,
      obligatorio:{
        valorAtributo: true
      }
    },
    {
      tipoInput: "SelectTAtributosFecha",
      nombreInput: "atributoFecha",
      labelInput: null,
      obligatorio:{
        valorAtributo: true
      }
    },
    {
      tipoInput: "SelectTAtributosTodos",
      nombreInput: "atributoTodos",
      labelInput: null,
      obligatorio:{
        valorAtributo: true
      }
    },
    {
      tipoInput: "InputNumberT",
      nombreInput: "esInpfddddutnumero",
      labelInput: "Hola Adios",
      obligatorio: {
        valor: true,
      },
    },
    {
      tipoInput: "DatePickerT2",
      nombreInput: "fechadfdfd",
      labelInput: "Es una fecha",
      obligatorio: {
        valor: true,
      },
    },
    {
      tipoInput: "RangePickerT2",
      nombreInput: "rangoFddfdfecha",
      labelInput: "Es un rango de fechas",
      obligatorio: {
        valor: true,
      },
    },
    {
      tipoInput: "InputT",
      nombreInput: "esInputSeweesdsloArray",
      labelInput: "INPUT FUERA ARRAY",
      obligatorio: {
        valor: true,
      },
    },
    {
      tipoInput: "SelectTAtributosNumero",
      nombreInput: "atributoNumero",
      labelInput: null,
      obligatorio: {
        valorAtributo: true
      }
    },
    {
      tipoInput: "SelectTAtributosTextoOpciones",
      nombreInput: "casaaaadfdfaaa",
      labelInput: null,
      obligatorio: {
        valorAtributo: true,
        valorAtributoOpcion: true
      },
    },
    {
      tipoInput: "SelectTCamposListado",
      nombreInput: "SelectTCamposListadoBonito",
      labelInput: null,
      obligatorio: {
        valorListado: true,
        valorListadoCampo: true
      },
    },
    {
      tipoInput: "FormArrayList",
      nombreList: "FormArrayLists",
      labelList: "holi",
      listaInputs: [
        {
          tipoInput: "SelectT",
          opciones: [
            { name: "UNO", value: "uno" },
            { name: "DOS", value: "dos" },
          ],
          nombreInput: "cadgfdgdfgsa1",
          labelInput: "Nombre a mostrar del input",
          obligatorio: {
            valor: true,
          },
        },
        {
          tipoInput: "SelectTListados",
          nombreInput: "casa2",
          labelInput: "Nombre a mostrar del input",
          obligatorio: {
            valor: true,
          },
        },
        {
          tipoInput: "SelectTCamposListado",
          nombreInput: "SelectTCamposListadoBonito",
          labelInput: null,
          obligatorio: {
            valorListado: true,
            valorListadoCampo: true,
          },
        },
        {
          tipoInput: "SelectTAtributosNumero",
          nombreInput: "amigos1",
          labelInput: null,
          obligatorio: {
            valorAtributo: true,
          },
        },
        {
          tipoInput: "SelectTAtributosTexto",
          nombreInput: "amigos2",
          labelInput: null,
          obligatorio: {
            valorAtributo: true,
          },
        },
        {
          tipoInput: "SelectTAtributosTextoOpciones",
          nombreInput: "casaaaaaaa",
          labelInput: null,
          obligatorio: {
            valorAtributo: true,
            valorAtributoOpcion: true,
          },
        },
        {
          tipoInput: "SelectTAtributosFecha",
          nombreInput: "amigos3",
          labelInput: null,
          obligatorio: {
            valorAtributo: true
          },
        },
        {
          tipoInput: "SelectTAtributosTodos",
          nombreInput: "amigos4",
          labelInput: null,
          obligatorio: {
            valorAtributo: true,
          },
        },
        {
          tipoInput: "InputT",
          nombreInput: "esInputSoloArray",
          labelInput: "INPUT EN ARRAY",
          obligatorio: {
            valor: true
          },
        },
        {
          tipoInput: "InputNumberT",
          nombreInput: "esInputnumero",
          labelInput: "Hola Adios",
          obligatorio: {
            valor: true
          },
        },
        {
          tipoInput: "DatePickerT2",
          nombreInput: "fecfeffha",
          labelInput: "Es una fecha",
          obligatorio: {
            valor: true
          },
        },
        {
          tipoInput: "RangePickerT2",
          nombreInput: "rangoFecha",
          labelInput: "Es un rango de fechas",
          obligatorio: {
            valor: true
          },
        },
      ],
    },
  ]


  const vari = [
    {
      nombreVariable: "nombreVar",
      tipoVariable: "tipoVar"
      }
  ]

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "10px 0 10px 0",
            }}
          >
            <label style={{ width: "200px", textAlign: "left" }}>Nombre</label>
            <InputT
              rules={{ required: true }}
              ancho="100%"
              control={control}
              name={"nombre"}
            ></InputT>
          </div>
          {errors?.nombre?.type === "required" && (
            <>
              <div style={{ color: "red" }}>Campo requerido</div>
            </>
          )}

          {/* <Form.Item name="_id" hidden rules={[{ required: true }]}></Form.Item> */}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "10px 0 10px 0",
            }}
          >
            <label style={{ width: "200px", textAlign: "left" }}>Título</label>
            <InputT
              rules={{ required: true }}
              ancho="100%"
              control={control}
              name={"titulo"}
            ></InputT>
          </div>
          {errors?.titulo?.type === "required" && (
            <>
              <div style={{ color: "red" }}>Campo requerido</div>
            </>
          )}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "10px 0 10px 0",
            }}
          >
            <label style={{ width: "200px", textAlign: "left" }}>
              Descripción
            </label>
            <InputT
              rules={{ required: true }}
              ancho="100%"
              control={control}
              name={"descripcion"}
            ></InputT>
          </div>
          {errors?.descripcion?.type === "required" && (
            <>
              <div style={{ color: "red" }}>Campo requerido</div>
            </>
          )}

          <CreadorFormularios
            listaDatos={listaDatos}
            insertOrUpdate={insertOrUpdate}
            variables={vari}
          ></CreadorFormularios>

          <div style={{ marginTop: "10px" }}>
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              style={{ marginRight: "40px" }}
            >
              Guardar
            </Button>
            <Button type="secondary" onClick={() => props.setVisible(false)}>
              Cancelar
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}

export default CrearActualizarWidgetMarlinBotonComponente;
