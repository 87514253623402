import { Button, ConfigProvider, Empty, Spin, Table } from "antd";
import React from "react";
import { Api } from "../../../../../api/configApi";
import { useForm } from "react-hook-form";
import Modal from "../Integrar/Modal";
import ModalCrearExpo from "../Integrar/ModalCrearExpo";
import {
  DatePickerT,
  InputNumberT,
  InputT,
  OptionT,
  SelectT,
} from "./../../../../../plugins/Formularios/FormularioTecclas";
import moment from "moment-timezone";
import "moment/locale/es";
import { WarningOutlined, FrownFilled } from "@ant-design/icons";
import { store } from "../../../../../store";

const DesignWidgetVisualizarExportarDatos = (props) => {
  const [widget, setWidget] = React.useState();
  const refCol = React.useRef([]);
  const [col, setCol] = React.useState(undefined);
  const refData = React.useRef([]);
  const [data, setData] = React.useState(undefined);
  const refDataExport = React.useRef([]);
  const [dataExport, setDataExport] = React.useState([]);
  const refDataFilter = React.useRef([]);
  const [dataFilter, setDataFilter] = React.useState([]);
  const [camposFiltro, setCamposFiltro] = React.useState([]);
  const refTotal = React.useRef(undefined);
  const [total, setTotal] = React.useState(undefined);
  const refTotalFilter = React.useRef(undefined);
  const [totalFilter, setTotalFilter] = React.useState(undefined);
  const refCargando = React.useRef(false);
  const [cargando, setCargando] = React.useState(false);
  const [cargandoFilter, setCargandoFilter] = React.useState(false);
  const [cargandoExport, setCargandoExport] = React.useState(false);
  const [mostrarTabla, setMostrarTabla] = React.useState(false);
  const [mostrar, setMostrar] = React.useState(false);
  const [mostrarCrearExpo, setMostrarCrearExpo] = React.useState(false);
  const refTotalExport = React.useRef();
  const [totalExport, setTotalExport] = React.useState(0);
  const refNombreTabla = React.useRef("");
  const [nombreTabla, setNombreTabla] = React.useState("Nombre predeterminado");
  const refShowTable = React.useRef(false);
  const [showTable, setShowTable] = React.useState(true);
  const refShowError = React.useRef();
  const [showError, setShowError] = React.useState(false);
  const refCargandoSpin = React.useRef();
  const [cargandoSpin, setCargandoSpin] = React.useState(true);
  const [actualPageFilter, setActualPageFilter] = React.useState(1);

  function hacerClick() {
    setMostrar(true);
  }
  function hacerClickCrearExpo() {
    setMostrarCrearExpo(true);
  }

  const {
    control,
    watch,
    handleSubmit,
    getValues,
    formState: { errors },
    reset,
  } = useForm();

  React.useEffect(() => {
    const widgetActual = store.widgets.find((wid) => {
      return wid._id === props._id;
    });
    setWidget(widgetActual);
  }, [props]);

  React.useEffect(() => {
    refNombreTabla.current = widget?.nombreTabla;
    setNombreTabla(widget?.nombreTabla);
    let totalCondiciones = 0;
    widget?.consultas?.forEach((consul, index) => {
      totalCondiciones += consul.condiciones?.length;
    });

    if (
      totalCondiciones >= widget?.consultas?.length &&
      widget?.project?.length > 0 &&
      widget?.consultas?.length > 0
    ) {
      obtenerTotal();
      refCargando.current = true;
      setCargando(true);
    }
  }, [total, widget]);

  // React.useEffect(() => {
  //   console.log("ochooooooo")
  // }, [totalFilter]);

  function obtenerDatos(page) {
    Api(
      "exportAndViewData",
      "exportAndViewData",
      {
        idWidget: widget?._id,
        nombreArchivo: "nombre",
        export: "NO",
        page: page || 1,
      },
      {},
      "http://localhost:5001"
    )
      .then((res) => {
        refCargandoSpin.current = false;
        setCargandoSpin(refCargandoSpin.current);

        res.data?.forEach((obj) => {
          for (const propiedad in obj) {
            if (
              moment(obj[propiedad]).isValid() &&
              obj[propiedad].length == 24
            ) {
              obj[propiedad] = moment
                .tz(
                  obj[propiedad],
                  widget?.timezone.slice(
                    widget?.timezone.indexOf("*") + 1,
                    widget?.timezone.length + 1
                  )
                )
                .format("MMMM D YYYY, h:mm:ss a");
            }
          }
        });

        refData.current = res.data;
        setData(refData.current);

        refCargando.current = false;
        setCargando(refCargando.current);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function obtenerCamposFiltro() {
    Api(
      "exportAndViewData",
      "obtenerCamposFilter",
      {
        idWidget: widget?._id,
        export: "NO",
      },
      {},
      "http://localhost:5001"
    )
      .then((res) => {
        if (refCol.current.length === 0) {
          res?.forEach((colum) => {
            refCol.current.push({
              title: colum.slice(0, colum.indexOf("-")),
              dataIndex: colum.slice(0, colum.indexOf("-")),
            });
          });

          setCamposFiltro(res);
          setCol(refCol.current);
        }
        obtenerDatos();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function filtrar(camp, valu, page) {
    Api(
      "exportAndViewData",
      "exportAndViewDataConFilter",
      {
        idWidget: widget?._id,
        export: "NO",
        filter: { campo: camp, value: valu },
        page: page || 1,
      },
      {},
      "http://localhost:5001"
    )
      .then((res) => {
        refCargandoSpin.current = false;
        setCargandoSpin(refCargandoSpin.current);
        res.data?.forEach((obj) => {
          for (const propiedad in obj) {
            if (
              moment(obj[propiedad]).isValid() &&
              obj[propiedad].length == 24
            ) {
              obj[propiedad] = moment
                .tz(
                  obj[propiedad],
                  widget?.timezone.slice(
                    widget?.timezone.indexOf("*") + 1,
                    widget.timezone.length + 1
                  )
                )
                .format("MMMM D YYYY, h:mm:ss a");
            }
          }
        });

        refDataFilter.current = res.data;

        setDataFilter(refDataFilter.current);

        setCargandoFilter(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function totalDataFiltrar(camp, valu) {
    setCargandoFilter(true);
    Api(
      "exportAndViewData",
      "countDataConFilter",
      {
        idWidget: widget?._id,
        export: "NO",
        filter: { campo: camp, value: valu },
        page: 1,
      },
      {},
      "http://localhost:5001"
    )
      .then((res) => {
        refTotalFilter.current = res.data;
        setTotalFilter(refTotalFilter.current);

        filtrar(camp, valu);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function obtenerTotal() {
    Api(
      "exportAndViewData",
      "countDocuments",
      {
        idWidget: widget?._id,
        export: "NO",
      },
      {},
      "http://localhost:5001"
    )
      .then((respuesta) => {
        if (respuesta?.error) {
          refShowError.current = true;
          setShowError(refShowError.current);
        }
        refShowTable.current = respuesta.mostrarTabla;
        setShowTable(refShowTable.current);
        if (typeof respuesta.data == "number") {
          refTotal.current = respuesta.data;
          setTotal(refTotal.current);
        }

        if (total > 0) {
          obtenerCamposFiltro();
        } else if (total == 0) {
          refCargandoSpin.current = false;
          setCargandoSpin(refCargandoSpin.current);
          refCargando.current = false;
          setCargando(refCargando.current);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function obtenerDatosExport(page) {
    Api(
      "exportAndViewData",
      "getAllExports",
      { page: page || 1 },
      {},
      "http://localhost:5001"
    )
      .then((res) => {
        setCargandoExport(false);
        refDataExport.current = res;
        setDataExport(refDataExport.current);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function obtenerTotalExport(pagina) {
    Api(
      "exportAndViewData",
      "countDocumentsExports",
      {},
      {},
      "http://localhost:5001"
    )
      .then((res) => {
        refTotalExport.current = res;
        setTotalExport(refTotalExport.current);
        obtenerDatosExport(pagina);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function abrirLinkDescargaExport(link) {
    window.location.assign(link);
  }

  function resetValorFiltro(campo) {
    let fields = getValues();
    fields.campo = campo;
    if (true) {
      delete fields?.value;
      reset(fields);
    }
  }

  function onSubmit(values) {
    setActualPageFilter(1);
    if (
      values.campo.slice(values.campo.indexOf("-") + 1, values.campo.length) ==
      "date"
    ) {
      let fechaFiltro = moment(values.value).format("YYYY-MM-DD");
      values.value = fechaFiltro;
    }

    totalDataFiltrar(values.campo, values.value);
    setMostrarTabla(true);
    setCargandoFilter(true);
  }

  const columnsExport = [
    {
      title: "Nombre del archivo",
      dataIndex: "nombreArchivo",
      key: "nombreArchivo",
    },
    {
      title: "Estado",
      dataIndex: "estado",
      key: "estado",
    },
    {
      title: "Descargar",
      dataIndex: "url",
      key: "url",
      render: (text) => {
        return (
          <>
            <Button onClick={() => abrirLinkDescargaExport(text)}>
              Descargar
            </Button>
          </>
        );
      },
    },
    {
      title: "Fecha de exportación",
      dataIndex: "fecha",
      key: "fecha",
      render: (text) => {
        return moment
          .tz(
            text,
            widget?.timezone.slice(
              widget?.timezone.indexOf("*") + 1,
              widget?.timezone.length + 1
            )
          )
          .format("MMMM DD YYYY, h:mm:ss a");
      },
    },
  ];

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          position: "relative",
        }}
      >
        {/* {total > 0 && totalFilter == undefined && (
          <>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "start",
                margin: "0",
                position: "absolute",
              }}
            >
              <Button
                onClick={() => {
                  setCargandoExport(true);
                  hacerClick();
                  obtenerTotalExport();
                }}
              >
                Exportar
              </Button>
            </div>
          </>
        )} */}

        {total > 0 && camposFiltro && showTable && (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div
                style={{
                  width: "300px",
                }}
              >
                <div
                  style={{
                    margin: "10px 0",
                    width: "100%",
                  }}
                >
                  <h4
                    style={{
                      width: "100%",
                      textAlign: "center",
                      margin: "0 0 10px 0",
                    }}
                  >
                    Filtrar
                  </h4>
                  <SelectT
                    showSearch
                    rules={{ required: true }}
                    control={control}
                    placeholder="Filtrar por"
                    name={"campo"}
                    ancho="100%"
                    onChange={(e) => {
                      resetValorFiltro(e);
                    }}
                  >
                    {camposFiltro?.map((camp, index) => {
                      let campoMod = camp.slice(0, camp.indexOf("-"));
                      return (
                        <>
                          <OptionT key={index + 7546} value={camp}>
                            {campoMod}
                          </OptionT>
                        </>
                      );
                    })}
                  </SelectT>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "10px 0 10px 0",
                    width: "100%",
                  }}
                >
                  {(watch("campo")?.slice(
                    watch("campo").indexOf("-") + 1,
                    watch("campo").length
                  ) == "text" ||
                    (watch("campo") &&
                      watch("campo")?.slice(
                        watch("campo").indexOf("-") + 1,
                        watch("campo").length
                      ) == "texto") ||
                    watch("campo")?.slice(
                      watch("campo").indexOf("-") + 1,
                      watch("campo").length
                    ) == "multiple" ||
                    watch("campo")?.slice(
                      watch("campo").indexOf("-") + 1,
                      watch("campo").length
                    ) == "email") && (
                    <>
                      <InputT
                        rules={{ required: true }}
                        control={control}
                        name={"value"}
                        ancho="100%"
                        placeholder="Ingresa el término de búsqueda"
                      ></InputT>
                    </>
                  )}
                  {(watch("campo")?.slice(
                    watch("campo").indexOf("-") + 1,
                    watch("campo").length
                  ) == "number" ||
                    (watch("campo") &&
                      watch("campo")?.slice(
                        watch("campo").indexOf("-") + 1,
                        watch("campo").length
                      ) == "numero")) && (
                    <>
                      <InputNumberT
                        rules={{ required: true }}
                        control={control}
                        name={"value"}
                        ancho="100%"
                        placeholder="Ingresa un número"
                      ></InputNumberT>
                    </>
                  )}
                  {watch("campo")?.slice(
                    watch("campo").indexOf("-") + 1,
                    watch("campo").length
                  ) == "date" && (
                    <>
                      <DatePickerT
                        control={control}
                        name={"value"}
                        ancho="300px"
                        rules={{ required: true }}
                      ></DatePickerT>
                    </>
                  )}
                  {watch("campo")?.slice(
                    watch("campo").indexOf("-") + 1,
                    watch("campo").length
                  ) == "crypto" && (
                    <>
                      <DatePickerT
                        control={control}
                        name={"value"}
                        ancho="300px"
                        rules={{ required: true }}
                      ></DatePickerT>
                    </>
                  )}
                </div>
                {errors?.value?.type == "required" && (
                  <div style={{ color: "red", margin: "0 0 10px 0" }}>
                    campo requerido
                  </div>
                )}

                {watch("campo") && (
                  <>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button style={{ marginRight: "10px" }} htmlType="submit">
                        Filtrar
                      </Button>
                      <Button
                        style={{ marginLeft: "10px" }}
                        onClick={() => {
                          setMostrarTabla(false);
                          obtenerTotal();
                          let fields = getValues();
                          delete fields.campo;
                          delete fields.value;
                          reset(fields);
                          setTotalFilter(undefined);
                          setTotal(undefined);
                        }}
                      >
                        Volver
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </form>
          </>
        )}

        <div style={{ width: "100%", marginTop: "30px" }}>
          <Modal
            mostrar={mostrar}
            color="#fff"
            setMostrar={setMostrar}
            ancho="70%"
            alto="auto"
            zIndexFondo={1000}
            zIndexModal={2000}
            borderRadius="10px"
          >
            <div
              style={{
                padding: "10px",
                height: "100%",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
              }}
            >
              <h3 style={{ margin: "20px 0 20px 0" }}>
                Historial de exportaciones
              </h3>
              {dataExport && columnsExport && (
                <>
                  <ConfigProvider
                    renderEmpty={() => (
                      <Empty description="Lo sentimos... no hay datos para mostrar" />
                    )}
                  >
                    <Table
                      loading={cargandoExport}
                      columns={columnsExport}
                      dataSource={dataExport}
                      size="small"
                      pagination={{
                        showSizeChanger: false,
                        pageSize: 6,
                        total: totalExport,
                        onChange: (page) => {
                          obtenerTotalExport(page);
                          // obtenerDatosExport(page);
                          setCargandoExport(true);
                        },
                      }}
                    />
                  </ConfigProvider>
                </>
              )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  marginTop: "5px",
                  marginBottom: "30px",
                }}
              >
                <div>
                  <Button onClick={hacerClickCrearExpo}>
                    Crear nueva exportación
                  </Button>
                </div>
                <div>
                  <Button
                    style={{
                      marginLeft: "50px",
                    }}
                    type="second"
                    danger
                    onClick={() => {
                      setMostrar(false);
                    }}
                  >
                    Cerrar
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            mostrar={mostrarCrearExpo}
            color="#fff"
            setMostrar={setMostrarCrearExpo}
            ancho="50%"
            alto="auto"
            zIndexFondo={2000}
            zIndexModal={3000}
            borderRadius="10px"
          >
            <ModalCrearExpo
              setMostrarCrearExpo={setMostrarCrearExpo}
              idWidget={widget?._id}
              obtenerTotalExport={obtenerTotalExport}
              setCargandoExport={setCargandoExport}
            />
          </Modal>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div>
              {showTable && (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "20px",
                    }}
                  >
                    <span style={{ width: "30%" }}></span>
                    <h4>{total > 0 && nombreTabla}</h4>
                    <div
                      style={{
                        width: "30%",
                        minWidth: "140px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {total >= 0 && (
                        <>
                          <span style={{ textAlign: "right" }}>
                            Total de registros: {total}
                          </span>
                        </>
                      )}
                      {totalFilter >= 0 && (
                        <span style={{ textAlign: "right" }}>
                          Total de registros filtrados: {totalFilter}
                        </span>
                      )}
                    </div>
                  </div>
                </>
              )}

              {!cargandoSpin && !showTable && !showError && total > 0 && (
                <>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "20px",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "30px",
                        fontWeight: "1000",
                        marginBottom: "5px",
                        width: "45px",
                        height: "45px",
                        backgroundColor: "#d1d1d1",
                        borderRadius: "50%",
                        color: "#fff",
                        textAlign: "center",
                      }}
                    >
                      !
                    </span>
                    <div
                      style={{
                        padding: "10px 20px",
                        borderRadius: "10px",
                        width: "50%",
                        color: "#949494",
                        textAlign: "center",
                      }}
                    >
                      Esta consulta es demasiado pesada para mostrar. Debes
                      exportarla para ver los registros.
                      <br />
                      Total de registros encontrados: {total}
                    </div>
                  </div>
                </>
              )}

              {showError && (
                <>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "20px",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "50px",
                      }}
                    >
                      <WarningOutlined />
                    </span>
                    <div
                      style={{
                        fontSize: "20px",
                        padding: "10px 20px",
                        borderRadius: "10px",
                        width: "50%",
                        color: "#949494",
                      }}
                    >
                      Hay un error
                    </div>
                  </div>
                </>
              )}

              {!mostrarTabla && showTable && col && data && (
                <>
                  <ConfigProvider
                    renderEmpty={() => (
                      <Empty description="Lo sentimos... no hay datos para mostrar" />
                    )}
                  >
                    <Table
                      loading={cargando}
                      emptyDescription="No hay datos"
                      columns={col}
                      dataSource={data}
                      size="small"
                      pagination={{
                        showSizeChanger: false,
                        pageSize: 20,
                        total: total,
                        onChange: (page) => {
                          obtenerDatos(page);
                          setCargando(true);
                        },
                      }}
                    />
                  </ConfigProvider>
                </>
              )}

              {cargandoSpin && (
                <div
                  style={{
                    margin: "80px 0",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Spin spinning={cargandoSpin}></Spin>
                </div>
              )}

              {total == 0 && !cargandoSpin && (
                <>
                  <div style={{ textAlign: "center" }}>
                    <FrownFilled style={{ fontSize: "50px" }} />
                  </div>
                  <div style={{ marginTop: "20px", textAlign: "center" }}>
                    No se encontraron resultados
                  </div>
                </>
              )}

              {mostrarTabla && (
                <>
                  <ConfigProvider
                    renderEmpty={() => (
                      <Empty description="Lo sentimos... no hay datos para mostrar" />
                    )}
                  >
                    <Table
                      loading={cargandoFilter}
                      columns={col}
                      dataSource={dataFilter}
                      size="small"
                      pagination={{
                        showSizeChanger: false,
                        pageSize: 20,
                        total: totalFilter,
                        current: actualPageFilter,

                        onChange: (page) => {
                          setActualPageFilter(page);
                          if (
                            getValues().campo.slice(
                              getValues().campo.indexOf("-") + 1,
                              getValues().campo.length
                            ) == "date"
                          ) {
                            filtrar(
                              getValues().campo,
                              moment(getValues().value).format("YYYY-MM-DD"),
                              page
                            );
                          } else {
                            filtrar(getValues().campo, getValues().value, page);
                          }

                          setCargandoFilter(true);
                        },
                      }}
                    />
                  </ConfigProvider>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DesignWidgetVisualizarExportarDatos;
