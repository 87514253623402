import { message, Input } from "antd";
import React from "react";
import { Api } from "../../api/configApi";
import { ButtonT, InputT } from "../../plugins/Formularios/FormularioTecclas";
import Modal from "../../plugins/Modal";
import { store } from "../../store";

const MonedaActivar = (props) => {
  const [mostrarActivarDesactivar, setMostrarActivarDesactivar] =
    React.useState(!props.estado);
  const [mostrar, setMostrar] = React.useState(false);
  const [mostrarCambiar, setMostrarCambiar] = React.useState(false);
  const [mostrarCambiarDispersion, setMostrarCambiarDispersion] =
    React.useState(false);
  const [cargandoActivar, setCargandoActivar] = React.useState(false);
  const refCargandoDesactivar = React.useRef();
  const [cargandoDesactivar, setCargandoDesactivar] = React.useState(false);
  const refMnemonic = React.useRef();
  const [mnemonic, setMnemonic] = React.useState();
  const refDireccion = React.useRef();
  const [direccion, setDireccion] = React.useState();
  const [privateKey, setPrivateKey] = React.useState();
  const [direccionCambiarDispersion, setDireccionCambiarDispersion] =
    React.useState();
  const [privateKeyCambiarDispersion, setPrivateKeyCambiarDispersion] =
    React.useState();
  const [direccionDeposito, setDireccionDeposito] = React.useState("");
  const [direccionDepositoCambiar, setDireccionDepositoCambiar] =
    React.useState("");
  const [mostrarDireccionDeposito, setMostrarDireccionDeposito] =
    React.useState(true);
  const [mostrarErrorDirDepos, setMostrarErrorDirDepos] = React.useState(false);
  const [mostrarErrorDirDeposCambiar, setMostrarErrorDirDeposCambiar] =
    React.useState(false);
  const [isLoadingNextButton, setIsLoadingNextButton] = React.useState(false);
  const [isLoadingUpdateButton, setIsLoadingUpdateButton] =
    React.useState(false);
  const [loadingCambiarDispersion, setLoadingCambiarDispersion] =
    React.useState(false);
  const [copiadoCambiarDispersion, setCopiadoCambiarDispersion] =
    React.useState(false);

  function copy(texto) {
    window.navigator.clipboard?.writeText(texto);
    message.success({ content: "Copiado al portapapeles" });
  }

  const success = () => {
    message.success({
      content: "Moneda desactivada",
      className: "custom-class",
      style: {
        marginTop: "50vh",
      },
    });
  };
  const alerta = () => {
    message.error({
      content:
        "Para desactivar esta moneda, debes asegurarte de que ningún widget la esté utilizando",
      className: "custom-class",
      style: {
        marginTop: "50vh",
      },
    });
  };

  function desactivarMoneda(moneda) {
    let bandera = true;
    let widTatu = store.widgets.filter(
      (el) => el.tipo == "pasarelas" && el.listaIntegraciones == "tatu"
    );
    widTatu.forEach((el) => {
      el.monedasPermitidas.forEach((mon) => {
        if (
          mon.moneda.slice(mon.moneda.indexOf("-") + 1, mon.moneda.length) ==
          moneda
        ) {
          bandera = false;
        }
      });
    });

    if (bandera) {
      setCargandoDesactivar(true);
      Api(
        "integracionPasarelaPulsof",
        "cambiarEstadoMoneda",
        { moneda: props.codigo.toLowerCase() },
        {},
        "https://841d-181-33-156-128.ngrok.io"
      )
        .then((res) => {
          success();
          refCargandoDesactivar.current = false;
          setCargandoDesactivar(refCargandoDesactivar.current);
          setMostrarActivarDesactivar(!mostrarActivarDesactivar);
        })
        .catch((err) => console.log(err));
    } else {
      alerta();
    }
  }

  const enviarDireccionDeposito = () => {
    setIsLoadingNextButton(true);
    setMostrarErrorDirDepos(false);
    Api(
      "integracionPasarelaPulsof",
      "generarWallet",
      { moneda: props.codigo.toLowerCase(), walletDeposito: direccionDeposito },
      {},
      "https://841d-181-33-156-128.ngrok.io"
    )
      .then((res) => {
        setIsLoadingNextButton(false);
        if (res.success) {
          refMnemonic.current = res.mnemonic;
          setMnemonic(refMnemonic.current);

          refDireccion.current = res.address;
          setDireccion(refDireccion.current);

          setPrivateKey(res.privateKey);
          setMostrarActivarDesactivar(!mostrarActivarDesactivar);
          setCargandoActivar(false);

          props.estadoMonedas();
          setMostrarDireccionDeposito(!mostrarDireccionDeposito);
        } else {
          setMostrarErrorDirDepos(true);
        }
      })
      .catch((err) => console.log(err));
  };

  const verificarWallet = () => {
    setCargandoActivar(true);
    Api(
      "integracionPasarelaPulsof",
      "activarWallet",
      { moneda: props.codigo.toLowerCase() },
      {},
      "https://841d-181-33-156-128.ngrok.io"
    )
      .then((res) => {
        if (res.next) {
          setMostrar(true);
        } else {
          setCargandoActivar(false);
          setMostrarActivarDesactivar(!mostrarActivarDesactivar);
        }
      })
      .catch((err) => console.log(err));
  };

  const modificarDeposito = () => {
    setMostrarErrorDirDeposCambiar(false);
    setIsLoadingUpdateButton(true);
    Api(
      "integracionPasarelaPulsof",
      "updateAddressDeposito",
      {
        currency: props.codigo.toLowerCase(),
        newWallet: direccionDepositoCambiar,
      },
      {},
      "https://841d-181-33-156-128.ngrok.io"
    )
      .then((res) => {
        if (!res.success) {
          setMostrarErrorDirDeposCambiar(true);
        } else {
          setMostrarCambiar(false);
          message.success("Depósito actualizado");
        }
        setIsLoadingUpdateButton(false);
      })
      .catch((err) => {
        console.log(err);
        message.error("Error al actualizar la dirección de depósito");
        setIsLoadingUpdateButton(false);
      });
  };

  const cambiarDireccionDispersion = () => {    
    if (!loadingCambiarDispersion) {
      setLoadingCambiarDispersion(true);
      Api(
        "integracionPasarelaPulsof",
        "updateAddressTarif",
        {
          currency: props.codigo.toLowerCase(),
        },
        {},
        "https://841d-181-33-156-128.ngrok.io"
      )
        .then((res) => {
          setLoadingCambiarDispersion(false);
          if (res.success) {
            setMostrarCambiarDispersion(true);
            setDireccionCambiarDispersion(res?.address);
            setPrivateKeyCambiarDispersion(res?.privateKey);
          } else {
            message.error(
              "Algo salió mal, debes tener tu cuenta sin saldo para poder realizar esta acción"
            );
          }
        })
        .catch((err) => {
          console.log(err);
          setLoadingCambiarDispersion(false);
        });
    }
  };

  React.useEffect(() => {
    if (!mostrar) {
      setCargandoActivar(false);
      setMostrarErrorDirDepos(false);
    }
  }, [mostrar]);

  React.useEffect(() => {
    if (!mostrarCambiar) {
      setMostrarErrorDirDeposCambiar(false);
    }
  }, [mostrarCambiar]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
      }}
      key={props.index * 32324}
    >
      <div style={{ width: "190px" }}>
        <p style={{ whiteSpace: "nowrap" }}>{props.nombre}</p>
      </div>

      <div style={{ minWidth: "290px", display: "flex", flex: 1 }}>
        {mostrarActivarDesactivar && (
          <ButtonT
            loading={cargandoActivar}
            style={{ width: "130px" }}
            onClick={() => {
              verificarWallet();
            }}
          >
            Activar
          </ButtonT>
        )}

        {!mostrarActivarDesactivar && (
          <ButtonT
            loading={cargandoDesactivar}
            style={{ width: "130px" }}
            onClick={() => {
              desactivarMoneda(props.codigo.toLowerCase());
            }}
          >
            Desactivar
          </ButtonT>
        )}

        {props.mostrarBoton && (
          <ButtonT
            style={{ width: "150px", marginLeft: "10px" }}
            onClick={() => {
              setMostrarCambiar(true);
            }}
          >
            Cambiar dirección
          </ButtonT>
        )}
        {props.mostrarBoton && (
          <ButtonT
            loading={loadingCambiarDispersion}
            style={{ marginLeft: "10px" }}
            onClick={() => {
              cambiarDireccionDispersion();
            }}
          >
            Cambiar dirección de dispersión
          </ButtonT>
        )}
      </div>

      <Modal
        mostrar={mostrarCambiar}
        setMostrar={setMostrarCambiar}
        ancho="auto"
        alto="auto"
      >
        <div>
          <div style={{ padding: "30px" }}>
            <p>Por favor ingresa tu dirección de depósito</p>
            <Input
              onBlur={(e) => {
                if (e.target.value.length > 0) {
                  setDireccionDepositoCambiar(e.target.value);
                } else {
                  setDireccionDepositoCambiar("");
                }
              }}
            ></Input>

            {mostrarErrorDirDeposCambiar && (
              <p style={{ color: "#ff0000", marginTop: "10px" }}>
                Dirección de depósito inválida
              </p>
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <ButtonT
                loading={isLoadingUpdateButton}
                style={{ width: "150px" }}
                onClick={() => {
                  if (direccionDepositoCambiar.length > 0) {
                    modificarDeposito();
                  }
                }}
              >
                Actualizar
              </ButtonT>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        mostrar={mostrarCambiarDispersion}
        setMostrar={setMostrarCambiarDispersion}
        ancho="auto"
        alto="auto"
        background="rgba(0, 0, 0, 0.1)"
        border="2px red solid"
      >
        <div style={{ padding: "30px" }}>
          <div style={{ padding: "30px 30px 0 30px" }}>
            <p
              style={{
                textAlign: "center",
                fontSize: "30px",
                fontWeight: "bold",
                margin: "0 0 20px 0",
                color: "red",
              }}
            >
              ¡ADVERTENCIA!
            </p>
            <p style={{ textAlign: "center" }}>
              hola a tros es ren unmensha ede advnttecua adi qwue dbeem estatrn
              erefparados par salir corridndo en cjso de qjue haya un
              aoicalusosi hola a tros es ren unmensha ede advnttecua adi qwue
              dbeem estatrn erefparados par salir corridndo en cjso de qjue haya
              un aoicalusosi
            </p>
            <p style={{ textAlign: "center" }}>PRIVATE KEY:</p>
            <p style={{ fontWeight: "bold", textAlign: "center" }}>
              {privateKeyCambiarDispersion}
            </p>
            <p style={{ textAlign: "center" }}>DIRECCIÓN:</p>
            <p style={{ fontWeight: "bold", textAlign: "center" }}>
              {direccionCambiarDispersion}
            </p>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <ButtonT onClick={() => copy(direccionCambiarDispersion)}>
                Copiar dirección
              </ButtonT>
            </div>
            {copiadoCambiarDispersion && (
              <p
                style={{
                  color: "green",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                ¡Copiado al portapapeles!
              </p>
            )}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <ButtonT
              loading={isLoadingUpdateButton}
              style={{ width: "150px" }}
              onClick={() => {
                setMostrarCambiarDispersion(false);
                setCopiadoCambiarDispersion(false);
              }}
            >
              Cerrar
            </ButtonT>
          </div>
        </div>
      </Modal>
      <Modal mostrar={mostrar} setMostrar={setMostrar} ancho="auto" alto="auto">
        <div>
          {mostrarDireccionDeposito ? (
            <div style={{ padding: "30px" }}>
              <p>Por favor ingresa tu dirección de depósito</p>
              <Input
                onBlur={(e) => {
                  if (e.target.value.length > 0) {
                    setDireccionDeposito(e.target.value);
                  } else {
                    setDireccionDeposito("");
                  }
                }}
              ></Input>

              {mostrarErrorDirDepos && (
                <p style={{ color: "#ff0000", marginTop: "10px" }}>
                  Dirección de depósito inválida
                </p>
              )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <ButtonT
                  loading={isLoadingNextButton}
                  style={{ width: "150px" }}
                  onClick={() => {
                    if (direccionDeposito.length > 0) {
                      enviarDireccionDeposito();
                    }
                  }}
                >
                  Siguiente
                </ButtonT>
              </div>
            </div>
          ) : (
            <div>
              <div style={{ padding: "30px 30px 0 30px" }}>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "30px",
                    fontWeight: "bold",
                    margin: "0 0 20px 0",
                    color: "red",
                  }}
                >
                  ¡ADVERTENCIA!
                </p>
                <p style={{ textAlign: "center" }}>
                  hola a tros es ren unmensha ede advnttecua adi qwue dbeem
                  estatrn erefparados par salir corridndo en cjso de qjue haya
                  un aoicalusosi hola a tros es ren unmensha ede advnttecua adi
                  qwue dbeem estatrn erefparados par salir corridndo en cjso de
                  qjue haya un aoicalusosi
                </p>
                <p style={{ textAlign: "center" }}>PRIVATE KEY:</p>
                <p style={{ fontWeight: "bold", textAlign: "center" }}>
                  {privateKey}
                </p>
                <p style={{ textAlign: "center" }}>DIRECCIÓN:</p>
                <p style={{ fontWeight: "bold", textAlign: "center" }}>
                  {direccion}
                </p>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <ButtonT onClick={() => copy(direccion)}>
                    Copiar dirección
                  </ButtonT>
                </div>
                <p style={{ textAlign: "center", margin: "15px 0" }}>
                  FRASE DE RECUPERACIÓN:
                </p>
                <p style={{ fontWeight: "bold", textAlign: "center" }}>
                  {mnemonic}
                </p>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <ButtonT onClick={() => copy(mnemonic)}>Copiar frase</ButtonT>
                </div>
              </div>
              <div
                style={{
                  padding: "30px",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <ButtonT
                  onClick={() => {
                    // setMostrar(false);
                    window.location.reload();
                  }}
                >
                  Aceptar
                </ButtonT>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default MonedaActivar;
